import React from 'react';
import { Box } from '@latitude/box';
import { Link } from '@latitude/link';
import { Metadata } from '@latitude/metadata';
import { SecondaryHero } from '@latitude/hero';
import { BUTTON_STYLE, COLOR } from '@latitude/core/utils/constants';
import { SITE_URL } from '../../utils/constants';
import {
  StyledGroup,
  StyledGroupItem,
  StyledCardTile,
  StyledCardTileContent
} from '../contact-us/components/_CardTile.styled';
import Layout from '../../components/layout';
import data from './cards.json';

const UploadPage = ({ location }) => (
  <Layout location={location}>
    <main className="navigation-spacer">
      <Metadata
        title="Document upload | Gem by Latitude"
        description="Document upload."
        canonical={`${SITE_URL}${location.pathname}`}
      />

      <SecondaryHero heading="Document upload" />

      <Box.Section backgroundColor={COLOR.GREY6}>
        <StyledGroup>
          {data.map(item => (
            <StyledGroupItem key={item.title}>
              <StyledCardTile
                image={
                  <img
                    // eslint-disable-next-line import/no-dynamic-require
                    src={require(`./images/${item.image}`)}
                    alt={item.title}
                  />
                }
                cardImageOrientation={item.orientation}
                title={item.title}
                content={
                  <StyledCardTileContent>
                    <Link
                      button={BUTTON_STYLE.TERTIARY}
                      href={`https://upload.gemfinance.co.nz/fileupload/income?cardType=${item.id}`}
                      trackId={item.id}
                      trackEventData={{
                        location: 'Document upload'
                      }}
                    >
                      Upload your documents
                    </Link>
                  </StyledCardTileContent>
                }
              />
            </StyledGroupItem>
          ))}
        </StyledGroup>
      </Box.Section>
    </main>
  </Layout>
);

export default UploadPage;
